import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PERUSAHAAN------
    getPerusahaanAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/all", { params: credentials })
    },
    getPerusahaanSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/single", { params: credentials })
    },
    verifPerusahaan(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/perusahaan", credentials)
    },

    // -----API DATA FARM PERUSAHAAN------
    getPerusahaanFarmAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/farm/all", { params: credentials })
    },
    getPerusahaanFarmSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/farm/single", { params: credentials })
    },
    verifPerusahaanFarm(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/perusahaan/farm", credentials)
    },

    // -----API DATA HATCHERY PERUSAHAAN------
    getPerusahaanHatcheryAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/hatchery/all", { params: credentials })
    },
    getPerusahaanHatcherySingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/perusahaan/hatchery/single", { params: credentials })
    },
    verifPerusahaanHatchery(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/perusahaan/hatchery", credentials)
    },
}