<template>
  <div id="verifikasi-master-data-farm-view">
    
    <div class="card card-custom border-lg border-0 border-primary">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/verifikasi/master-data/farm"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              View Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::View Data Farm-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="mx-5" v-if="dataFarm != null">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-65 symbol-light d-sm-inline d-none mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-sm-3x svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <a class="text-dark-75 text-hover-primary mb-1 font-size-h5 font-weight-bolder">
                          Farm {{ dataFarm.nama }} 
                          <span class="text-muted font-size-sm d-sm-inline d-none">({{ dataFarm.kode }})</span>
                        </a>
                        <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mb-1 mr-sm-5">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataFarm.sistemkandang.uraian }}</span>
                            </div>
                            <div class="d-flex align-items-center mb-1">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Home/Home.svg"/>
                                <!--end::Svg Icon-->
                              </span>                           
                              <span class="text-muted font-weight-bold">{{ dataFarm.kepemilikan.uraian }} {{ dataFarm.kepemilikan_name ? `(${dataFarm.kepemilikan_name})` : '' }}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                          <b-badge :variant="dataFarm.sts.color">{{ dataFarm.sts.uraian }}</b-badge>
                        </div>
                    </div>
                    <!--end::Info-->

                </div>
                <!--end::Top-->

                <!--begin::Bottom-->
                <div class="pt-3">
                    <!--begin::Text-->
                    <div class="d-flex align-items-center pt-5 mb-2">
                      <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                        <!--end::Svg Icon-->
                      </span>                           
                      <span class="text-dark-75 font-size-lg font-weight-normal">
                          Provinsi {{ dataFarm.provinsi.uraian }}, 
                          {{ dataFarm.kabupaten.uraian }}, 
                          Kecamatan {{ dataFarm.kecamatan.uraian }}, 
                          Desa {{ dataFarm.desa.uraian }}, 
                          {{ dataFarm.alamat }}
                      </span>
                    </div>
                    <!--end::Text-->

                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-6 mb-4"></div>
                    <!--end::Separator-->

                    <!--begin::Description-->
                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Penanggung Jawab
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div class="d-flex align-items-center mx-sm-7 mx-2">
												<!--begin::Symbol-->
												<div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
													</span>
												</div>
												<!--end::Symbol-->
												<!--begin::Text-->
												<div class="d-flex flex-column flex-grow-1">
													<a class="text-success text-hover-primary font-size-lg font-weight-bolder">{{ dataFarm.pj }}  (owner)</a>
                          <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Credit-card.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataFarm.pj_nik }}</span>
                            </div>
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Communication/Active-call.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">+62{{ dataFarm.pj_telp }}</span>
                            </div>
                          </div>
												</div>
												<!--end::Text-->
											</div>
                    </div>

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/General/Attachment2.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Rincian
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <b-row class="mx-sm-7 mx-2">
                        <b-col cols="12" sm="6" class="p-0">
                          <div class="d-flex flex-column flex-grow-1">
                            <span class="text-primary font-weight-bold">
                              Jumlah Kandang
                            </span>
                            <span class="mb-2 text-muted font-weight-bold">
                              {{ dataFarm.jumlah_kandang | nominal }} kandang
                            </span>
                          </div>
                        </b-col>

                        <b-col cols="12" sm="6" class="p-0">
                          <div class="d-flex flex-column flex-grow-1">
                            <span class="text-primary font-weight-bold">
                              Jumlah Flock
                            </span>
                            <span class="mb-2 text-muted font-weight-bold">
                              {{ dataFarm.jumlah_flock | nominal }} flok
                            </span>
                          </div>
                        </b-col>

                        <b-col cols="12" sm="6" class="p-0">
                          <div class="d-flex flex-column flex-grow-1">
                            <span class="text-primary font-weight-bold">
                              Kapasitas
                            </span>
                            <span class="mb-2 text-muted font-weight-bold">
                              {{ dataFarm.kapasitas | nominal }} ekor
                            </span>
                          </div>
                        </b-col>

                        <b-col cols="12" sm="6" class="p-0">
                          <div class="d-flex flex-column flex-grow-1">
                            <span class="text-primary font-weight-bold">
                              Jumlah SDM
                            </span>
                            <span class="mb-2 text-muted font-weight-bold">
                              {{ dataFarm.sdm | nominal }} orang
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Map Lokasi
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div class="rounded iframe-content">
                        <div id="gmaps" ref="goomaps"></div>
                      </div>
                      
                    </div>                   

                    <!--end::Description-->
                </div>
                <!--end::Bottom-->
              </div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>

            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { FacebookLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/perusahaan.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-farm-view",
  props: {
    idF: {
      required: true
    }
  },
  components: {
    FacebookLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataFarm: null,
      gooMaps: null,
      gooMarker: null,
    }
  },
  computed:{
    ...mapGetters(["currentUser"]),
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    getData () {
      VerifikasiService.getPerusahaanFarmSingle({ farm : this.idF })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataFarm = { ...res.data.data, geotag: JSON.parse(res.data.data.geotag) }
            
            this.$nextTick(() => {
              setTimeout(() => {
                this.initMaps()              
              }, 500);
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    initMaps() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(this.$refs.goomaps, {
            center: this.dataFarm.geotag,
            zoom: 17,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },

          });
          this.gooMarker = new google.maps.Marker({ position: this.dataFarm.geotag, map: this.gooMaps });
        })
        .catch(err => {
          ErrorService.message({
            action: 'Peringatan',
            message: 'Maps gagal dimuat',
          })
        })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Farm" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
<style lang="scss">
  #verifikasi-master-data-farm-view {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }
  }

</style>
